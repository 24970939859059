<template>
    <div class="grid">
        <div class="col-12">
            <div class="font-medium text-xl text-900 mb-3"><span class="text-primary text-2xl">/</span> {{ title }}</div>
            <div class="text-500 mb-5">Preencha os campos abaixo</div>
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <div :style="loading ? 'opacity: 30%;' : ''">
                <div class="card p-fluid w-full">
                    <div class="text-800 mb-1"><span class="text-primary pi pi-info-circle mb-3" v-tooltip="tooltip"></span> {{ subtitle }}</div>
                    <Toast />
                    <div class="">
                        <div class="grid">
                            <div class="field col-6">
                                <label for="code">Código</label>
                                <InputText id="code" v-model.trim="form.code" autofocus autocomplete="off" />
                            </div>
                            <div class="field col-6">
                                <label for="activeContract">Contrato</label>
                                <SelectButton v-model="form.activeContract" :options="contractStates" optionLabel="name" optionValue="value" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12">
                                <label for="code_int">Tipo de Contrato</label>
                                <Dropdown
                                    id="state"
                                    v-model="form.type"
                                    :options="tipoPrestador"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Selecione o tipo... "
                                    :class="{ 'p-invalid': submitted && !form.type }"
                                >
                                </Dropdown>
                                <small class="p-error" v-if="submitted && !form.type">Tipo é obrigatório.</small>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12">
                                <span class="text-primary pi pi-info-circle mb-3 mr-1" v-tooltip="'Ordena a classificação do prestador'"></span>
                                <label for="code_int">Classificação</label>
                                <Dropdown
                                    id="state"
                                    v-model="form.prioridade"
                                    :options="prioridadePrestador"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Selecione a classificação... "
                                    :class="{ 'p-invalid': submitted && !form.prioridade }"
                                >
                                </Dropdown>
                                <small class="p-error" v-if="submitted && !form.prioridade">Classificação é obrigatória.</small>
                            </div>
                        </div>

                        <div class="grid">
                            <div class="field col-4">
                                <label for="binary" class="mr-2 ml-0">Não permitir agendamento para o prestador</label>
                                <SelectButton
                                    id="clinical"
                                    v-model="form.naoPermitidoRealizarAgendamento"
                                    :options="[
                                        { label: 'Sim', value: true },
                                        { label: 'Não', value: false }
                                    ]"
                                    optionLabel="label"
                                    optionValue="value"
                                />
                            </div>
                            <div class="field col-4">
                                <label for="binary" class="mr-2 ml-0">Atende ASO Retido</label>
                                <SelectButton
                                    id="clinical"
                                    v-model="form.atendeAsoRetido"
                                    :options="[
                                        { label: 'Sim', value: true },
                                        { label: 'Não', value: false }
                                    ]"
                                    optionLabel="label"
                                    optionValue="value"
                                />
                            </div>
                            <div class="field col-4">
                                <label for="binary" class="mr-2 ml-0">Enviar link para confirmação de horários</label>
                                <SelectButton
                                    id="clinical"
                                    v-model="form.permiteConfirmacaoPrestador"
                                    :options="[
                                        { label: 'Sim', value: true },
                                        { label: 'Não', value: false }
                                    ]"
                                    optionLabel="label"
                                    optionValue="value"
                                />
                            </div>
                        </div>

                        <div class="field">
                            <label for="name">Nome</label>
                            <InputText
                                id="name"
                                v-model.trim="form.name"
                                required="true"
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.name }"
                            />
                            <small class="p-error mr-6" v-if="submitted && !form.name">Nome é obrigatório.</small>
                        </div>

                        <div class="field">
                            <label for="tradeName">Razão social</label>
                            <InputText
                                id="tradeName"
                                v-model.trim="form.tradeName"
                                required="true"
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.tradeName }"
                            />
                            <small class="p-error" v-if="submitted && !form.tradeName">Razão social é obrigatório.</small>
                        </div>

                        <div class="field">
                            <label for="document">CNPJ</label>
                            <InputMask
                                id="document"
                                mask="99.999.999/9999-99"
                                v-model.trim="form.cnpj"
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.cnpj }"
                            />
                            <small class="p-error" v-if="submitted && !form.cnpj">CNPJ é obrigatório.</small>
                        </div>

                        <div class="grid">
                            <div class="field col-6">
                                <label for="agenda">Agenda</label>
                                <DropdownAgenda id="agenda" v-model="form.agenda" :idValue="form.idAgenda"></DropdownAgenda>
                            </div>
                            <div class="field col-6">
                                <label for="daysInAdvance">Dias mínimos de antecedência da consulta</label>
                                <InputText id="daysInAdvance" v-model="form.daysInAdvance" />
                            </div>
                        </div>

                        <div class="field">
                            <label for="cidadesAtendidas">Cidade atendida</label>
                            <MultiSelect
                                v-model="form.cities"
                                :filter="true"
                                @filter="loadCidade"
                                :options="cidades"
                                optionValue="id"
                                optionLabel="cidade"
                                placeholder="Selecione as cidades atendidas"
                                display="chip"
                                :showToggleAll="false"
                                :disabled="form.atendeTodasCidades"
                                :class="{ 'p-invalid': submitted && (!form.cities || !form.cities.length) && !form.atendeTodasCidades }"
                            />
                            <small class="p-error" v-if="submitted && (!form.cities || !form.cities.length) && !form.atendeTodasCidades"
                                >Cidade atendida obrigatória.</small
                            >
                        </div>
                        <div class="col-2 flex m-0 pl-0">
                            <label for="binary" class="ml-0 mr-2">Atende todas as cidades</label>
                            <InputSwitch v-model="form.atendeTodasCidades" default="false" @click="form.cities = []" />
                        </div>
                        <h5 class="mt-6 mb-4">Endereço</h5>

                        <div>
                            <div class="grid">
                                <div class="field col-6">
                                    <label for="zipCode">CEP</label>
                                    <InputMask
                                        id="zipCode"
                                        mask="99999-999"
                                        v-model.trim="form.zipCode"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.zipCode }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.zipCode">CEP é obrigatório.</small>
                                </div>

                                <div class="field col-6">
                                    <label for="neighborhood">Bairro</label>
                                    <InputText
                                        id="neighborhood"
                                        v-model.trim="form.neighborhood"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.neighborhood }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.neighborhood">Bairro é obrigatório.</small>
                                </div>
                            </div>

                            <div class="grid">
                                <div class="field col-6">
                                    <label for="street">Rua</label>
                                    <InputText
                                        id="street"
                                        v-model.trim="form.street"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.street }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.street">Rua é obrigatório.</small>
                                </div>
                                <div class="field col-6">
                                    <label for="number">Número</label>
                                    <InputText id="number" v-model.trim="form.number" autocomplete="off" />
                                    <small class="p-error" v-if="submitted && !form.number">Numero é obrigatório.</small>
                                </div>
                            </div>

                            <div class="grid">
                                <div class="field col-12">
                                    <label for="city">Cidade</label>
                                    <DropdownCidade v-model="form.city" :class="{ 'p-invalid': submitted && !form.city }" />
                                    <small class="p-error" v-if="submitted && !form.city">Cidade é obrigatório.</small>
                                </div>
                            </div>

                            <div class="field">
                                <label for="addressComplement">Complemento</label>
                                <InputText id="addressComplement" v-model.trim="form.addressComplement" autocomplete="off" />
                            </div>
                            <div class="field">
                                <label for="addressReference">Referência</label>
                                <InputText id="addressReference" v-model.trim="form.addressReference" autocomplete="off" />
                            </div>
                        </div>

                        <h5 class="mt-6 mb-4">
                            Alerta prestador
                            <i
                                class="pi pi-info-circle justificativa-icon"
                                v-tooltip="'Alerta exibido ao selecionar um prestador no painel de agendamento.'"
                                style="color: #1c80cf"
                            ></i>
                        </h5>
                        <Textarea v-model="form.prestadorAlerta" rows="5" cols="30" />

                        <h5 class="mt-6 mb-4">Contato</h5>
                        <div>
                            <div class="field">
                                <label for="email">E-mail</label>
                                <InputText
                                    id="email"
                                    v-model.trim="form.email"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && (!form.email || !emailValido || !emailUnico) }"
                                />
                                <small class="p-error" v-if="submitted && !form.email">E-mail é obrigatório.</small>
                                <small class="p-error" v-if="submitted && form.email && !emailValido"> Por favor insira um e-mail válido. </small>
                                <small class="p-error" v-if="submitted && form.email && emailValido && !emailUnico"> Insira um único e-mail </small>
                            </div>
                            <div class="grid">
                                <div class="field col-6">
                                    <label for="phone">Telefone</label>
                                    <InputText
                                        id="phone"
                                        v-mask="['(##) ####-####', '(##) #####-####']"
                                        placeholder="(_) _____-____"
                                        v-model.trim="form.phone"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.phone && !form.commercialPhone }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.phone && !form.commercialPhone"
                                        >Telefone ou Telefone Comercial é obrigatório.</small
                                    >
                                </div>

                                <div class="field col-6">
                                    <label for="commercial_phone">Telefone Comercial</label>
                                    <InputText
                                        id="commercial_phone"
                                        v-model.trim="form.commercialPhone"
                                        v-mask="['(##) ####-####', '(##) #####-####']"
                                        placeholder="(_) _____-____"
                                        autocomplete="off"
                                    />
                                    <small class="p-error" v-if="submitted && !form.phone && !form.commercialPhone"
                                        >Telefone ou Telefone Comercial é obrigatório.</small
                                    >
                                </div>
                            </div>
                        </div>

                        <h5 class="mt-6 mb-4">Sistemas e Tecnologias utilizadas</h5>
                        <div>
                            <div class="field">
                                <label for="empresas">Sistemas e Tecnologias</label>
                                <Dropdown
                                    id="systemsAndTechnology"
                                    v-model="form.systemsAndTechnology"
                                    :options="paginationSistemas"
                                    optionLabel="name"
                                    dataKey="id"
                                    placeholder="Selecione..."
                                />
                            </div>
                        </div>
                        <div v-if="false">
                            <div class="field">
                                <label for="codigoEmpresaPrincipal">Código da Empresa Principal</label>
                                <InputText
                                    id="codigoEmpresaPrincipal"
                                    v-model.trim="form.codigoEmpresaPrincipal"
                                    :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.codigoEmpresaPrincipal }"
                                    autocomplete="off"
                                    autofocus
                                    required="true"
                                />
                                <small v-if="form.systemsAndTechnology.name == 'SOC' && submitted && !form.codigoEmpresaPrincipal" class="p-error"
                                    >Código da Empresa Principal é obrigatório.</small
                                >
                            </div>

                            <div class="field">
                                <label for="chaveAcesso">Chave de Acesso/Password</label>
                                <InputText
                                    id="chaveAcesso"
                                    v-model.trim="form.chaveAcesso"
                                    :class="{ 'p-invalid': form.systemsAndTechnology.name == 'SOC' && submitted && !form.chaveAcesso }"
                                    autocomplete="off"
                                    autofocus
                                    required="true"
                                />
                                <small v-if="form.systemsAndTechnology.name == 'SOC' && submitted && !form.chaveAcesso" class="p-error"
                                    >Chave de Acesso/Password é obrigatório.</small
                                >
                            </div>

                            <div class="field">
                                <label for="username">Username</label>
                                <InputText
                                    id="username"
                                    v-model.trim="form.username"
                                    :class="{ 'p-invalid': form.systemsAndTechnology.name == 'SOC' && submitted && !form.username }"
                                    autocomplete="off"
                                    autofocus
                                    required="true"
                                />
                                <small v-if="form.systemsAndTechnology.name == 'SOC' && submitted && !form.username" class="p-error"
                                    >Username é obrigatório.</small
                                >
                            </div>

                            <div class="field">
                                <label for="codigoResponsavel">Código Responsável</label>
                                <InputText
                                    id="codigoResponsavel"
                                    v-model.trim="form.codigoResponsavel"
                                    :class="{ 'p-invalid': form.systemsAndTechnology.name == 'SOC' && submitted && !form.codigoResponsavel }"
                                    autocomplete="off"
                                    autofocus
                                    required="true"
                                />
                                <small v-if="form.systemsAndTechnology.name == 'SOC' && submitted && !form.codigoResponsavel" class="p-error"
                                    >Código Responsável é obrigatório.</small
                                >
                            </div>

                            <div class="field">
                                <label for="codigoUsuario">Código Usuário SOC</label>
                                <InputText
                                    id="codigoUsuario"
                                    v-model.trim="form.codigoUsuarioSoc"
                                    :class="{ 'p-invalid': form.systemsAndTechnology.name == 'SOC' && submitted && !form.codigoUsuarioSoc }"
                                    autocomplete="off"
                                    autofocus
                                    required="true"
                                />
                                <small v-if="form.systemsAndTechnology.name == 'SOC' && submitted && !form.codigoUsuarioSoc" class="p-error"
                                    >Código Usuário SOC é obrigatório.</small
                                >
                            </div>
                            <div class="grid">
                                <div class="field col-6">
                                    <label for="codigoExportaDados">Código exporta dados de Agendas</label>
                                    <InputText
                                        id="codigoExportaDados"
                                        v-model.trim="form.codigoExportaDados"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': form.systemsAndTechnology.name == 'SOC' && submitted && !form.codigoExportaDados }"
                                    />
                                    <small class="p-error" v-if="form.systemsAndTechnology.name == 'SOC' && submitted && !form.codigoExportaDados"
                                        >codigo exporta Dados é obrigatório.</small
                                    >
                                </div>

                                <div class="field col-6">
                                    <label for="chaveExportaDados">Chave exporta dados de Agendas</label>
                                    <InputText
                                        id="chaveExportaDados"
                                        v-model.trim="form.chaveAcessoExportaDados"
                                        autocomplete="off"
                                        :class="{
                                            'p-invalid': form.systemsAndTechnology.name == 'SOC' && submitted && !form.chaveAcessoExportaDados
                                        }"
                                    />
                                    <small
                                        class="p-error"
                                        v-if="form.systemsAndTechnology.name == 'SOC' && submitted && !form.chaveAcessoExportaDados"
                                        >Chave de acesso ao exporta dados é obrigatória.</small
                                    >
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col-5 flex">
                                    <label for="binary" class="mr-2 ml-0">Gerar ASO com data</label>
                                    <InputSwitch v-model="form.gerarAsoComData" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 class="mt-4 mb-4">Dados para faturamento</h5>
                            <div class="grid">
                                <div class="col-12 flex align-items-center gap-2 py-2">
                                    <label for="binary" class="mr-2 ml-0">Depósito antecipado</label>
                                    <InputSwitch v-model="form.advanceDeposit" />
                                </div>
                                <div
                                    v-if="form.advanceDeposit"
                                    :class="{ 'md:col-6': form.politicaReembolso === enumPoliticaReembolso.COBRA_FALTA_CONSULTA }"
                                    class="col-12 flex flex-column gap-2"
                                >
                                    <label for="politicaReembolso">Política de Reembolso/Falta</label>
                                    <Dropdown
                                        id="politicaReembolso"
                                        v-model="form.politicaReembolso"
                                        :options="politicasDeReembolso"
                                        :class="{ 'p-invalid': submitted && !form.politicaReembolso }"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Selecione..."
                                    />
                                    <small class="p-error" v-if="submitted && !form.politicaReembolso"> Campo obrigatório. </small>
                                </div>
                                <div
                                    v-if="form.politicaReembolso === enumPoliticaReembolso.COBRA_FALTA_CONSULTA"
                                    class="col-12 md:col-6 flex align-items-center gap-2 pt-6"
                                >
                                    <label for="binary" class="mr-2 ml-0">Gerar crédito</label>
                                    <InputSwitch v-model="form.gerarCredito" />
                                </div>
                                <div class="field col">
                                    <label for="empresas">Créditos do Prestador</label>
                                    <InputNumber id="saldo" v-model="this.form.saldo" :disabled="true" mode="currency" currency="BRL"></InputNumber>
                                </div>
                                <div class="field col">
                                    <label for="empresas">Método de pagamento</label>
                                    <Dropdown
                                        id="metodoPagamento"
                                        v-model="form.metodoPagamento"
                                        :options="metodoPagamento"
                                        optionLabel="name"
                                        optionValue="value"
                                        dataKey="id"
                                        placeholder="Selecione..."
                                        :class="{
                                            'p-invalid': submitted && !form.metodoPagamento && form.advanceDeposit
                                        }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.metodoPagamento && form.advanceDeposit"
                                        >Preenchimento Obrigatório</small
                                    >
                                </div>
                                <div v-if="form.metodoPagamento === metodoPagamentoEnum.PIX" class="field col">
                                    <label for="empresas">Chave Pix</label>
                                    <InputText
                                        id="chavePix"
                                        v-model="form.chavePix"
                                        :options="metodoPagamento"
                                        :class="{
                                            'p-invalid':
                                                submitted && form.metodoPagamento === metodoPagamentoEnum.PIX && !form.chavePix && form.advanceDeposit
                                        }"
                                    />
                                    <small
                                        class="p-error"
                                        v-if="submitted && form.metodoPagamento === metodoPagamentoEnum.PIX && !form.chavePix && form.advanceDeposit"
                                        >Preenchimento Obrigatório</small
                                    >
                                </div>
                            </div>
                            <div>
                                <div class="field">
                                    <label for="nomeTitularConta">Nome titular da conta</label>
                                    <InputText
                                        id="titularConta"
                                        v-model.trim="form.nomeTitularConta"
                                        autocomplete="off"
                                        :class="{
                                            'p-invalid':
                                                submitted &&
                                                !form.nomeTitularConta &&
                                                form.advanceDeposit &&
                                                form.metodoPagamento !== metodoPagamentoEnum.PIX
                                        }"
                                        :maxlength="form.metodoPagamento === metodoPagamentoEnum.PIX ? 100 : null"
                                    />
                                    <small
                                        class="p-error"
                                        v-if="
                                            submitted &&
                                            !form.nomeTitularConta &&
                                            form.advanceDeposit &&
                                            form.metodoPagamento !== metodoPagamentoEnum.PIX
                                        "
                                        >Preenchimento Obrigatório</small
                                    >
                                </div>
                                <div v-if="form.metodoPagamento != metodoPagamentoEnum.PIX" class="grid">
                                    <div class="field col-6">
                                        <label for="cpfTitularConta">CPF titular</label>
                                        <InputMask
                                            id="cpfTitularConta"
                                            mask="999.999.999-99"
                                            v-model.trim="form.cpfTitularConta"
                                            autocomplete="off"
                                        />
                                    </div>
                                    <div v-if="form.metodoPagamento != metodoPagamentoEnum.PIX" class="field col-6">
                                        <label for="cnpjTitularConta">CNPJ titular</label>
                                        <InputMask
                                            id="cnpjTitularConta"
                                            mask="99.999.999/9999-99"
                                            v-model.trim="form.cnpjTitularConta"
                                            autocomplete="off"
                                            :class="{
                                                'p-invalid': submitted && !form.cnpjTitularConta && !form.cpfTitularConta && form.advanceDeposit
                                            }"
                                        />
                                        <small
                                            class="p-error"
                                            v-if="submitted && !form.cnpjTitularConta && !form.cpfTitularConta && form.advanceDeposit"
                                            >Preenchimento Obrigatório quando não possuir CPF do titular</small
                                        >
                                    </div>
                                </div>
                                <div class="grid">
                                    <div v-if="form.metodoPagamento != metodoPagamentoEnum.PIX" class="field col-6">
                                        <label for="codigoBanco">Código do Banco</label>
                                        <InputMask
                                            id="codigoBanco"
                                            mask="999"
                                            v-model.trim="form.codigoBanco"
                                            autocomplete="off"
                                            :class="{ 'p-invalid': submitted && !form.codigoBanco && form.advanceDeposit }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.codigoBanco && form.advanceDeposit"
                                            >Preenchimento Obrigatório</small
                                        >
                                    </div>
                                    <div class="field col-6">
                                        <label for="nomeBanco">Nome do Banco</label>
                                        <InputText
                                            id="nomeBanco"
                                            v-model.trim="form.nomeBanco"
                                            autocomplete="off"
                                            :class="{
                                                'p-invalid':
                                                    submitted &&
                                                    !form.nomeBanco &&
                                                    form.advanceDeposit &&
                                                    form.metodoPagamento !== metodoPagamentoEnum.PIX
                                            }"
                                            :maxlength="form.metodoPagamento === metodoPagamentoEnum.PIX ? 100 : null"
                                        />
                                        <small
                                            class="p-error"
                                            v-if="
                                                submitted &&
                                                !form.nomeBanco &&
                                                form.advanceDeposit &&
                                                form.metodoPagamento !== metodoPagamentoEnum.PIX
                                            "
                                            >Preenchimento Obrigatório</small
                                        >
                                    </div>
                                </div>
                                <div v-if="form.metodoPagamento != metodoPagamentoEnum.PIX" class="grid">
                                    <div class="field col-6">
                                        <label for="codigoAgencia">Codigo Agência</label>
                                        <InputText
                                            id="codigoAgencia"
                                            v-model.trim="form.codigoAgencia"
                                            autocomplete="off"
                                            :class="{ 'p-invalid': submitted && !form.codigoAgencia && form.advanceDeposit }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.codigoAgencia && form.advanceDeposit"
                                            >Preenchimento Obrigatório
                                        </small>
                                        <small class="p-info">Formatos aceitos: 0000 | 0000-0</small>
                                    </div>
                                    <div class="field col-6">
                                        <label for="contaCorrente">Conta Corrente</label>
                                        <InputText
                                            id="contaCorrente"
                                            v-model.trim="form.contaCorrente"
                                            autocomplete="off"
                                            :class="{ 'p-invalid': submitted && !form.contaCorrente && form.advanceDeposit }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.contaCorrente && form.advanceDeposit"
                                            >Preenchimento Obrigatório
                                        </small>
                                        <small class="p-info">Formatos aceitos: 00000-0 | 000000-0 | 0000000-0</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" :disabled="btnEstaCarregando" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/supplier')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '../../services/BaseService';
import DropdownCidade from '../cidades/components/DropdownCidade.vue';
import TipoServicoPrestador from '../../enums/TipoServicoPrestador';
import MetodoPagamentoEnum from '../../enums/MetodoPagamentoEnum';
import DropdownAgenda from '../agenda/components/DropdownAgenda';
import EnumPrioridadePrestador from '../../enums/EnumPrioridadePrestador';
import { EnumPoliticaReembolsoLabel } from '../../enums/EnumPoliticaReembolso';
import EnumPoliticaReembolso from '../../enums/EnumPoliticaReembolso';
import { getCurrentCompany } from '@/services/store';
import { showError } from '../../utils/Toast';
import { validarEmails } from '@/utils/Email';

export default {
    components: {
        DropdownAgenda,
        DropdownCidade
    },
    data() {
        return {
            emailValido: true,
            emailUnico: false,
            btnEstaCarregando: false,
            metodoPagamento: [],
            tooltip: 'Dados gerais do prestador',
            metodoPagamentoEnum: MetodoPagamentoEnum,
            enumPoliticaReembolso: EnumPoliticaReembolso,
            subtitle: 'Prestador',
            paginationSistemas: [],
            horarios: [
                { id: 1, periodo: '08:00 - 12:00' },
                { id: 2, periodo: '13:30 - 18:00' }
            ],
            weekDays: [
                { value: 'DOM', name: 'Domingo' },
                { value: 'SEG', name: 'Segunda' },
                { value: 'TER', name: 'Terça' },
                { value: 'QUA', name: 'Quarta' },
                { value: 'QUI', name: 'Quinta' },
                { value: 'SEX', name: 'Sexta' },
                { value: 'SAB', name: 'Sábado' }
            ],
            serviceTypes: [
                { value: 'ORDEM_CHEGADA', name: 'Ordem de chegada' },
                { value: 'HORARIO_MARCADO', name: 'Horário marcado' }
            ],
            cidades: [],
            tipoPrestador: [
                { value: 'SAUDE', label: 'Saúde' },
                { value: 'SEGURANCA', label: 'Segurança' },
                { value: 'AMBOS', label: 'Ambos' },
                { value: 'SEM_CONTRATO', label: 'Sem Contrato' }
            ],
            tipo: [],
            perPage: 0,
            filter: [],
            total: 0,
            page: 1,
            loading: false,
            companies: [],
            customers: [],
            contractStates: [
                { name: 'Ativo', value: true },
                { name: 'Inativo', value: false }
            ],
            form: {
                openingHours: [],
                preferredSupplier: false,
                atendeAsoRetido: false,
                advanceDeposit: false,
                activeContract: true,
                atendeTodasCidades: false,
                email: null
            },
            submitted: false,
            fieldErrors: [],
            prioridadePrestador: []
        };
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar prestador' : 'Adicionar prestador';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        },
        politicasDeReembolso() {
            return Object.entries(EnumPoliticaReembolsoLabel).map(([key, value]) => ({ label: value, value: key }));
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        },
        'form.agenda'(newValue) {
            this.form.idAgenda = newValue?.id;
        },
        'form.advanceDeposit'(newValue) {
            this.MetodoPagamentoOptions();
            if (!newValue) this.form.politicaReembolso = undefined;
            else if (!this.form.politicaReembolso) {
                this.form.politicaReembolso = this.enumPoliticaReembolso.GERA_CREDITO;
            }
        },
        'form.politicaReembolso'(novoValor, antigoValor) {
            if (novoValor === this.enumPoliticaReembolso.COBRA_FALTA_CONSULTA) {
                this.form.gerarCredito = true;
            }
            if (antigoValor === this.enumPoliticaReembolso.COBRA_FALTA_CONSULTA) {
                this.form.gerarCredito = undefined;
            }
        }
    },
    async mounted() {
        this.loading = true;
        this.$serviceSistemas = new BaseService('/systems_and_technologies');
        this.$service = new BaseService('/suppliers');
        this.$serviceOpeningHours = new BaseService('/supplier/opening-hours');
        this.$serviceCustomer = new BaseService('/customers');
        this.$serviceCompanies = new BaseService('/companies');
        this.$serviceCidade = new BaseService('/cidades');
        this.MetodoPagamentoOptions();

        try {
            await Promise.all([this.load(), this.loadCidade()]);
            this.tipoOptions();
            this.prioridadeOptions();
            if (this.$route.params.id) {
                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = data;
                this.form.city = data.city;
                this.form.cities = data.cities.map((e) => e.id);
            }
        } catch (error) {
            this.loading = false;
            this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar informações!', detail: error.message, life: 5000 });
        }
        this.loading = false;
    },
    methods: {
        tipoOptions() {
            this.tipo = Object.keys(TipoServicoPrestador).map(function (type) {
                return { label: `${TipoServicoPrestador[type]}`, value: `${type}` };
            });
        },
        prioridadeOptions() {
            this.prioridadePrestador = Object.keys(EnumPrioridadePrestador).map(function (type) {
                return { label: `${EnumPrioridadePrestador[type]}`, value: `${type}` };
            });
        },
        MetodoPagamentoOptions() {
            this.metodoPagamento = [
                { name: 'PIX', value: this.metodoPagamentoEnum.PIX },
                { name: 'Faturamento', value: this.metodoPagamentoEnum.FATURAMENTO },
                { name: 'Transferência', value: this.metodoPagamentoEnum.TRANSFERENCIA }
            ];
            if (this.form.advanceDeposit) {
                this.metodoPagamento = [
                    { name: 'PIX', value: this.metodoPagamentoEnum.PIX },
                    { name: 'Transferência', value: this.metodoPagamentoEnum.TRANSFERENCIA }
                ];
            }
        },
        async verificarMultiplosEmails() {
            this.form.email = this.form.email.replace(/\s*;\s*/g, ';').trim();
            const emails = this.form.email.split(';');
            return emails.length <= 1;
        },
        async hasError() {
            this.emailValido = validarEmails(this.form.email);
            this.emailUnico = !this.$route.params.id ? await this.verificarMultiplosEmails() : true;
            this.submitted = true;
            this.fieldErrors = [];
            let hasError = false;

            if (!this.form.email || !this.emailValido || !this.emailUnico) {
                this.fieldErrors.push('Email');
                hasError = true;
            }

            if (!this.form.commercialPhone && !this.form.phone) {
                this.fieldErrors.push('Contato');
                hasError = true;
            }

            if (
                !this.form.type ||
                !this.form.prioridade ||
                !this.form.name ||
                !this.form.tradeName ||
                !this.form.zipCode ||
                !this.form.street ||
                !this.form.city ||
                !this.form.neighborhood ||
                !this.form.number ||
                ((!this.form.cities || !this.form.cities.length) && !this.form.atendeTodasCidades)
            ) {
                this.fieldErrors.push('Endereço');
                hasError = true;
            }

            if (this.form.advanceDeposit && !this.form.politicaReembolso) {
                this.fieldErrors.push('Política de Reembolso/Falta');
                hasError = true;
            }

            if (!this.form.metodoPagamento) {
                this.fieldErrors.push('Método de pagamento');
                hasError = true;
            }

            if (
                this.form.advanceDeposit &&
                this.form.metodoPagamento === this.metodoPagamentoEnum.TRANSFERENCIA &&
                (!this.form.nomeTitularConta ||
                    (!this.form.cnpjTitularConta && !this.form.cpfTitularConta) ||
                    !this.form.codigoBanco ||
                    !this.form.contaCorrente ||
                    !this.form.codigoAgencia ||
                    !this.form.nomeBanco ||
                    !this.form.metodoPagamento)
            ) {
                this.fieldErrors.push('Dados para faturamento');
                hasError = true;
            }

            if (
                (this.form.advanceDeposit && this.form.metodoPagamento === this.metodoPagamentoEnum.PIX && !this.form.chavePix) ||
                (this.form.advanceDeposit && this.form.metodoPagamento === this.metodoPagamentoEnum.PIX && this.form.chavePix === '')
            ) {
                this.fieldErrors.push('Chave Pix');
                hasError = true;
            }

            return hasError;
        },
        async load() {
            const sistemas = await this.$serviceSistemas.findAll({});
            this.paginationSistemas = sistemas.data;
        },
        async loadCidade() {
            const { data } = await this.$serviceCidade.findAll({
                limit: this.perPage,
                page: this.page,
                filter: this.filter
            });
            data.items.forEach((item) => (item.cidade = `${item.cidade} - ${item.estado}`));
            this.cidades = data.items;
            this.total = data.meta?.totalItems;
            this.totalPage = data.meta?.totalPages;
        },
        async save() {
            this.submitted = true;
            this.btnEstaCarregando = true;
            if (this.form?.id) {
                const { data } = await this.$service.findById(this.form.id);
                if (Number(data.saldo) != Number(this.form.saldo)) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Saldo do prestador',
                        detail: 'Seu saldo não condiz com a nossa base!',
                        life: 3000
                    });
                }
            }
            try {
                this.form.commercialPhone = '' || undefined ? this.form.phone : this.form.commercialPhone;
                const submitDto = JSON.parse(JSON.stringify(this.form));
                if (submitDto.cities) {
                    const prestadorCidades = this.cidades.filter((e) => submitDto.cities.includes(e.id));
                    submitDto.cities = prestadorCidades;
                }
                if (await this.hasError()) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Campos obrigatórios não preenchidos!',
                        detail: `Verifique os campos em destaque! ${this.fieldErrors.join(', ')}`,
                        life: 3000
                    });
                }
                await this.$service.save(submitDto);

                this.$toast.add({ severity: 'success', summary: 'Prestador salvo com sucesso!', life: 3000 });
                this.$router.replace('list');
            } catch (err) {
                showError(this.$toast, err);
            } finally {
                this.btnEstaCarregando = false;
            }
        }
    }
};
</script>
