const EnumPoliticaReembolso = {
  COBRA_FALTA_CONSULTA: 'COBRA_FALTA_CONSULTA',
  GERA_CREDITO: 'GERA_CREDITO',
  GERA_REEMBOLSO: 'GERA_REEMBOLSO'
}

export const EnumPoliticaReembolsoLabel = {
  [EnumPoliticaReembolso.COBRA_FALTA_CONSULTA]: 'Cobra falta consulta',
  [EnumPoliticaReembolso.GERA_CREDITO]: 'Gera crédito',
  [EnumPoliticaReembolso.GERA_REEMBOLSO]: 'Gera reembolso',
};

export default EnumPoliticaReembolso;
